.App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    display: block;
    text-overflow: wrap;
    overflow: hidden;
    --padding-left: env(safe-area-inset-left);
    --padding-right: env(safe-area-inset-right);
    --padding-bottom: env(safe-area-inset-bottom);
    --padding-top: env(safe-area-inset-top);
}


.no-scrollbar {
    scrollbar-width: "none";
}

.no-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}